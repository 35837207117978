import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';



const swiper = new Swiper('.swiper', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 4000,
  slidesPerView: 1,
  loop: true,
  draggable: false,
  allowTouchMove: false,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});



// お知らせのタブイベント
(function(){

  // タブの親要素
  const parentTabs  = document.querySelectorAll('.jsTab');

  // ない場合はなにもしない
  if( parentTabs.length === 0 ) return;

  // 指定した親要素をループ
  for( const tab of parentTabs ) {

    // タブのラベル要素
    const tabs = tab.querySelectorAll('[role="tab"]');

    // ラベルのクリックイベント
    tabs.forEach((item) => {
      item.onclick = (e) => {
        const target = e.target;
        const parent = target.parentNode.parentNode;
        const grandparent = parent.parentNode;
        // タブから現在すべての選択状態を取り除きます
        parent
          .querySelectorAll('[aria-selected="true"]')
          .forEach((t) => t.setAttribute('aria-selected', false));

        // このタブを選択されたタブとして設定します
        target.setAttribute('aria-selected', true);

        // すべてのタブパネルを非表示にします
        grandparent
          .querySelectorAll('[role="tabpanel"]')
          .forEach((p) => p.setAttribute('hidden', true));

        // 選択されたパネルを表示します
        grandparent.parentNode
          .querySelector(`#${target.getAttribute('aria-controls')}`)
          .removeAttribute('hidden');
      };
    }); // ラベルのクリックイベント

  }; // 指定した親要素をループ
}());
